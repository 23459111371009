import React, { useContext } from 'react';
import { Card, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import 'assets/scss/custom/botSummary.scss';
import { BotSummary as textLang } from 'staticData/languages';
import AppContext from 'context/Context';
import { botGeneralInfo, exchangeImgMap } from 'staticData/common';
import { coinsData } from 'services/coins/common';
import Avatar, { AvatarGroup } from 'components/common/Avatar';
import { capitalize } from 'helpers/utils';
import SoftBadge from 'components/common/SoftBadge';
import Flex from 'components/common/Flex';
import ButtonGrid from 'pages/CreateBot/ButtonGrid';

const BotSummary = ({
  botType,
  assets,
  exchange,
  total,
  botName = null,
  bgStyle = null,
  riskLevel = null
}) => {
  const {
    config: { lang }
  } = useContext(AppContext);
  const botTypeName = botName ? botName : botGeneralInfo[botType].name;

  return (
    <Card className="sticky" style={{ backgroundImage: bgStyle }}>
      <Card.Body as={Flex} className="justify-content-center">
        {/* Table with summary of custom parameters */}
        <Table borderless className="mb-0">
          <tbody>
            <tr className="border-bottom">
              <th className="ps-0">
                {botName ? (
                  <h5 className="me-2">{'AI Portfolio'}</h5>
                ) : (
                  <Flex>
                    <h5 className="me-2">{textLang.typeAgent[lang]}</h5>
                    {exchange === 'virtual' && (
                      <SoftBadge
                        pill
                        bg={'info'}
                        className="d-flex align-items-center"
                      >
                        {textLang.virtualMode[lang]}
                      </SoftBadge>
                    )}
                  </Flex>
                )}
              </th>
              <th className="pe-0 text-end pb-3">
                <h5>{botTypeName}</h5>
              </th>
            </tr>
            <tr className="">
              <th className="ps-0 text-dark">Crypto</th>
              <th className="pe-0 text-end pb-3">
                <AvatarGroup className="justify-content-end ps-2 pt-1">
                  {assets.map((coin, index) => (
                    <Avatar
                      key={index}
                      src={coinsData[coin].img}
                      name={coinsData[coin]}
                      isExact
                      imgStyle={{
                        zIndex: assets.length - index
                      }}
                      size="s"
                    />
                  ))}
                </AvatarGroup>
              </th>
            </tr>
            {riskLevel && (
              <tr className="">
                <th className="ps-0 text-dark">{textLang.riskLevel[lang]}</th>
                <th className="pe-0 d-flex pb-3 justify-content-end">
                  <ButtonGrid actives={[riskLevel]} buttonSize={'sm'} />
                </th>
              </tr>
            )}
            <tr className="">
              <th className="ps-0 text-dark">Exchange</th>
              <th className="pe-0 text-dark pb-3">
                <Flex alignItems="center" justifyContent="end">
                  <b className="me-1">{capitalize(exchange)}</b>
                  <Avatar
                    src={exchangeImgMap[capitalize(exchange)]}
                    name={exchange}
                    isExact
                    size="s"
                  />
                </Flex>
              </th>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
      {/* Footer with total */}
      {total != null && (
        <Card.Footer className="d-flex justify-content-between bg-light">
          <h5 className="mb-0">{textLang.total[lang]}</h5>
          <h5>{'$ ' + total}</h5>
        </Card.Footer>
      )}
    </Card>
  );
};

BotSummary.propTypes = {
  botType: PropTypes.string.isRequired,
  assets: PropTypes.array.isRequired,
  exchange: PropTypes.string,
  fee: PropTypes.number,
  total: PropTypes.number,
  botName: PropTypes.string,
  bgStyle: PropTypes.string,
  riskLevel: PropTypes.number
};

export default BotSummary;
