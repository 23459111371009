import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import 'assets/scss/custom/ButtonGrid.scss';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonGrid as textLang } from 'staticData/languages';
import AppContext from 'context/Context';

const interpolateColor = (color1, color2, factor) => {
  var result = color1
    .slice(1)
    .match(/.{2}/g)
    .map(hex => parseInt(hex, 16));
  return (
    '#' +
    result
      .map((start, i) => {
        var end = parseInt(color2.slice(1).match(/.{2}/g)[i], 16);
        var val = Math.round((1 - factor) * start + factor * end).toString(16);
        return val.length === 1 ? '0' + val : val;
      })
      .join('')
  );
};

const createStringAndColor = (number, lang) => {
  if (number <= 4) return [textLang.low[lang], '#00d27a'];
  else if (number <= 6) return [textLang.medium[lang], '#e5be01'];
  else return [textLang.high[lang], '#e63757'];
};

const ButtonGrid = ({
  actives = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  colored = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  click = false,
  hover = false,
  buttonSize = 'md',
  className = '',
  selected = null,
  useNumber = false,
  action = () => {},
  showCaret = true
}) => {
  const {
    config: { lang }
  } = useContext(AppContext);
  const buttons = [];
  const color1 = '#5cff5a';
  const color2 = '#ff4645';

  // convert if necessary to numbers
  actives = actives.map(Number);
  colored = colored.map(Number);
  if (selected) selected = Number(selected);

  for (let i = 0; i <= 10; i++) {
    const isInColorRange = colored.includes(i);
    const color = isInColorRange
      ? useNumber
        ? interpolateColor(color1, color2, (i - 1) / 9)
        : createStringAndColor(i, lang)[1]
      : 'transparent';
    const border = isInColorRange ? 'none' : '1px solid var(--falcon-800)';
    (actives.includes(i) && useNumber) ||
    (colored.includes(i) && actives.includes(i))
      ? buttons.push(
          <span
            className="justify-content-center"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
            onClick={() => isInColorRange && action(i)}
          >
            <button
              key={i}
              style={{ backgroundColor: color, border: border }}
              className={classNames(
                `square-button m${useNumber ? 'e' : 'x'}-1 button-size-${
                  useNumber ? buttonSize : 'string'
                }`,
                {
                  'square-button-hover': hover && isInColorRange,
                  'square-button-click': click && isInColorRange,
                  'square-button-selected': selected === i,
                  'fs--1': !useNumber && buttonSize === 'sm'
                }
              )}
            >
              {useNumber ? i : `${createStringAndColor(i, lang)[0]}`}
            </button>
            {selected === i && showCaret && (
              <FontAwesomeIcon
                icon={'caret-up'}
                className={classNames('', {
                  'fs-2': !useNumber && buttonSize === 'md'
                })}
              />
            )}
          </span>
        )
      : null;
  }

  return (
    <div
      className={`button-container ${className}`}
      style={selected != null ? { alignItems: 'flex-start' } : {}}
    >
      {buttons}
    </div>
  );
};

ButtonGrid.propTypes = {
  start: PropTypes.number,
  end: PropTypes.number,
  colorStart: PropTypes.number,
  colorEnd: PropTypes.number,
  click: PropTypes.bool,
  hover: PropTypes.bool,
  buttonSize: PropTypes.oneOf(['sm', 'md', 'lg']),
  className: PropTypes.string,
  selected: PropTypes.number,
  action: PropTypes.func,
  actives: PropTypes.arrayOf(PropTypes.number),
  colored: PropTypes.arrayOf(PropTypes.number),
  useNumber: PropTypes.bool,
  showCaret: PropTypes.bool
};

export default ButtonGrid;
